<template>
	<div class="lump" :style="lumpBg">
		<img class="title" src="@/assets/images/newyear/ranking-title.png" alt="">
		<div class="content">
			<p class="tip">排行说明</p>
			<p>1、积分算法：1钻石等于1积分。</p>
			<p>2、钻石数 > 充值时间（优先判断活动期间充值的总钻石数，钻石相同的前提，再判断充值时间，先充值则排行在前）</p>
			<p>3、 以活动时间内的累计充值钻石数进行排行，系统返利的钻石不计算在活动内。如：购买490钻石（首充赠送49钻石），则活动只计算490钻石。</p>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				lumpBg: {
					background: `#fd4749 url(${require("@/assets/images/newyear/bg.png")})`,
					backgroundSize: '100% auto',
					backgroundRepeat: 'no-repeat'
				}
			}
		}
	}
</script>

<style lang="stylus" scoped>
	.lump
		padding 1.2rem 0 0.426666rem
		box-sizing border-box
		height 100vh
		.title
			display block
			margin 0 auto
			width 3.573333rem
			height 1.333333rem
		.content
			margin 0.3rem 0.373333rem
			padding 0.746666rem 0.48rem 0.4rem
			box-sizing border-box
			height 68%
			background #FFFFFF
			box-shadow 0px 8px 19px 1px rgba(194, 23, 26, 0.73)
			border-radius 0.48rem
			text-align left
			p
			font-size 13px
			color #333333
			line-height 0.59rem
			.tip
				margin-bottom 0.16rem
				font-weight bold
</style>
